import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import "../style/app.css";

const App = () => {
  const [facts, setFacts] = useState([]);
  const factCount = 8;

  const getFacts = async (factCount) => {
    let tempFacts = [];
    for (let x = 0; x < factCount; x++) {
      try {
        const response = await axios.get(
          "https://uselessfacts.jsph.pl/api/v2/facts/random?language=de"
        );
        tempFacts.push(response.data);
      } catch (error) {
        console.error("Error fetching fact:", error);
      }
    }
    return tempFacts;
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await getFacts(factCount);
      setFacts(fetchedData);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="app-container">
        <div className="title-container">
          <h1>MS Fakten</h1>
        </div>
        <div className="fact-container">
        {facts.map((temp, index) => {
          console.log(temp);
          return (
              <Card key={index}>
                <Card.Body>{temp.text}</Card.Body>
                <Card.Footer>
                <span>von <a href={temp.source_url}><Badge bg="secondary">{temp.source}</Badge></a></span>
                </Card.Footer>
              </Card>
          );
        })}
        </div>
      </div>
    </>
  );
};

export default App;
